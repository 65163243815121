// !!!! - DO NOT MODIFY - !!!!
// THIS FILE IS DEPLOYED AUTOMATICALLY BY MATTv2.
//
//  Deployment info:
//    Updated at:     2024-08-20T08:53:48+00:00
//    Cause:          Manual deployment.
//    Deployment ID:  183268
//    Project:        559
//    Environment:    5442 - master
//    MD5:            4c64088afc6b6dcc089ec31b9240c341
//    Signature:      eyJpdiI6Imp1Q1kzMDY5eW9KNzJNOG9uS0JYZ3c9PSIsInZhbHVlIjoicWZxMGU0MHpJVW81azBOanQxWE1PdHc2Rkt1Zkp6MVFWTElxNVwvUEJTbjVDU01MTm1mZDVObE52c3J3dDZ0d01XXC9UaDZOS2ZmaHFORytNREQyK01EYUZ5XC9QRGlLNTFCUytXVldcLzJpcWM3SFErS2ZRSUxYODJlek9FMlViWjM0XC9jbkpTcTVCV1lpNUNodXZBNXBsWW5pU293NnN4SUNpZnVRSlwvMk91STVHODBsNmNOOTZUNXJjU3Nkc0VXbVwvbXhYMjM4UTh1UE5QVGxQaHA3TmFoNllKaGQ5YWV3c0JEd2pPWkdBOWd3dGJKcVpKeEluQlhjbERpRFZ5enV3K0NDKzFTVHZIVkUrMUdid0dYZyt1ZndJK05ad2Y0QmZ6cmhZaytnUDl4a2hxbWJVbFVXXC9LV1lXVk5lRVFnb0hZbGE5Z2xcLzJzbWZnYjNNXC9cL1lKdHdWcnhGdzBcL2NSSEs5STdka1dmczNpWm5Qb2p1ZkpkMk5ncVFjM0tYOWptWEFobFZXWWpWM3hTOGljMllFTFZLOG9lUT09IiwibWFjIjoiNzlkYWU3ZWY3YTcwNTYyNDE5ZjA2MDhhOWZmN2FjMTMzM2M2NWM3YzZmMWU1ZjVkZTExMTY1NGU5OTY0OTRjYSJ9
/**
 * Include your custom JavaScript here.
 *
 * We also offer some hooks so you can plug your own logic. For instance, if you want to be notified when the variant
 * changes on product page, you can attach a listener to the document:
 *
 * document.addEventListener('variant:changed', function(event) {
 *   var variant = event.detail.variant; // Gives you access to the whole variant details
 * });
 *
 * You can also add a listener whenever a product is added to the cart:
 *
 * document.addEventListener('product:added', function(event) {
 *   var variant = event.detail.variant; // Get the variant that was added
 *   var quantity = event.detail.quantity; // Get the quantity that was added
 * });
 *
 * If you just want to force refresh the mini-cart without adding a specific product, you can trigger the event
 * "cart:refresh" in a similar way (in that case, passing the quantity is not necessary):
 *
 * document.documentElement.dispatchEvent(new CustomEvent('cart:refresh', {
 *   bubbles: true
 * }));
 */

// function collectionFunctions(){
//   var parent = $('[data-section-type="collection"]');
//   var paginate = parent.data('paginate');
//     // var totalCustomBanners = parent.data('banners');
//   // var productsToShow = paginate - totalCustomBanners;
//   page = 1;
//   if($('.Pagination__NavItem.is-active').length > 0){
//   page = Number($('.Pagination__NavItem.is-active').data('page'));
//   }

//   var substractPages = paginate * (page - 1);
  
//   $('.banner-holder:not(.added)').each(function(){
//     var elem = $(this);
//     var bannerHtml = elem.html();
//     var position = Number(elem.data('position')) - 1 - substractPages;

//     console.log('paginate: ',paginate,'; page: ',page,'; position:',position, ';substractPages:',substractPages);
    
//     if($('.ProductList .Grid__Cell:eq('+position+')').length == 1 && position > 0){
//       elem.find('.Grid__Cell').insertBefore($('.ProductList .Grid__Cell:eq('+position+')'));
//       elem.addClass('added');
//     }
//     else{
//     }
//   })
// }

function quickAddToCart(form){
  
    $.ajax({
        type: "POST",
        url: '/cart/add',
        data: form.serialize(), // serializes the form's elements.
        success: function (data) {
          console.log("we made it!");
          document.documentElement.dispatchEvent(
            new CustomEvent("product:added", {
              bubbles: true,
              detail: {
                quantity: 1
              }
            })
          );
          
        }
      });
  
}

function faqFunctions(){
  $('.tab-title').click(function(){
    var index = $(this).data('index');
    $(this).addClass('active');
    $(this).siblings().removeClass('active');
    
    $('.tab-content[data-index="'+index+'"]').slideDown(300);
    $('.tab-content[data-index="'+index+'"]').siblings().slideUp(300);
  })
}

function toggleOption(form, variantName, value){
console.log('toggleOption');
  
    var variant = form.find('[name=id] option[data-title="'+ variantName +'"]');
  
  if(variant.length > 0){
  
      if(!variant.is(':disabled')){
        value.removeClass('disabled');  
      }
    else{
        value.addClass('disabled');
  console.log('.product-single__variants option[data-title="'+ variantName +'"] should be disabled');
    }
  }
  else{
value.addClass('disabled');
  }
  
}

function checkOptionTree(option){
console.log('checkOptionTree');
    var form = $('.ProductForm');
  
  
var index = option.data('option-index');
    var toFind = '.ProductForm [name="id"] option';
  if(index == 2){
    if(form.find('.ProductForm__Option[data-option-index="1"]').is("input")){
      toFind = toFind + '[data-option-1="'+ form.find('.ProductForm__Option[data-option-index="1"]').val() +'"]';
    }
      else{
      toFind = toFind + '[data-option-1="'+ form.find('.ProductForm__Option[data-option-index="1"] [type="radio"]:checked').val() +'"]';
      }
    }
console.log('index: ',index,'; toFind: ', toFind);
  
  if(option.is("input")){
      console.log('option is input');
    
    var findString = toFind + '[data-option-'+ index +'="'+ option.val() +'"]:not([disabled])';
      
      option.removeClass('disabled');
      
    if($(findString).length){
      console.log(findString + 'tree is successful');
      
    }
    else{
        console.log(findString + 'tree is not successful');
      option.addClass('disabled');
    }
  }
    else{
      console.log('option is list');
  option.find('[type="radio"]').each(function(){
  
    var findString = toFind + '[data-option-'+ index +'="'+ $(this).val() +'"]:not([disabled])';
      
      console.log('findString:', findString);
      $(this).removeClass('disabled');
      
    if($(findString).length > 0){
      console.log(findString + ' tree is successful');
      
    }
    else{
        console.log(findString + ' tree is not successful');
      $(this).addClass('disabled');
    }
    
  })
    }

}
  
function updateAvailableOptions(form, elem){
  console.log('updateAvailableOptions');
     var form = $('.ProductForm');
  
      var select = form.find('[name="id"]');
      
     var optionsLength = form.find('.ProductForm__Option:not(.no-js)').length;
  
    var mainOpt = $('.ProductForm__Option[data-option-index="1"]');
  
  console.log('updateAvailableOptions is running fine; optionsLength: ' + optionsLength);
  
  if(optionsLength == 1){
    if(mainOpt.is("input")){
       var variantName = $(this).val();
      toggleOption(form,variantName, $(this));
    }
      else{
       mainOpt.find('[type="radio"]').each(function(){
     console.log('main option');
      var variantName = $(this).val();
    
      toggleOption(form,variantName, $(this));
       
    })
      }
  }
  else if(optionsLength == 2){
    console.log('optionsLength is 2');
    
       checkOptionTree(mainOpt);
    if(mainOpt.is("input")){
      var variantFirstName = mainOpt.val();
    }
    else{
      var variantFirstName = mainOpt.find('[type=radio]:checked').val();
    }
    
    var secOpt = $('.ProductForm__Option[data-option-index="2"]');
  
    
     secOpt.find('[type="radio"]').each(function(){
       console.log('new radio found: ', $(this).val());
       
      var variantSecondName = $(this).val();
    
  
       var variantName = variantFirstName + ' / ' + variantSecondName;
         toggleOption(form,variantName, $(this));

       console.log('variantName: ',variantName);
       
       
     })
    
  }
     else if(optionsLength == 3){
       
    
    if(mainOpt.is("input")){
      var variantFirstName = mainOpt.val();
    }
    else{
      var variantFirstName = mainOpt.find('[type=radio]:checked').val();
    }
    var secOpt = $('.ProductForm__Option[data-option-index="2"]');
  
       checkOptionTree(secOpt);
       checkOptionTree(mainOpt);

        if(secOpt.is("input")){
      var variantSecondName = secOpt.val();
    }
    else{
       var variantSecondName = secOpt.find('[type=radio]:checked').val();
    }
     

      console.log('third level reached');
         var thirdOpt = $('.ProductForm__Option[data-option-index="3"]');
  
          thirdOpt.find('[type="radio"]').each(function(){
            console.log('new option found');
          var variantThirdName = $(this).val();
            
       var variantName = variantFirstName + ' / ' + variantSecondName + ' / ' + variantThirdName;
         toggleOption(form,variantName, $(this));
          })
     }
}


function vertScrollFunctions(){

  function adjustArrows(wrapper){
    var parent = wrapper.find('.scroll-area');
        var parentHeight = parent.outerHeight();
        var parentScrollHeight = parent[0].scrollHeight;
        var parentScrollTop = parent.scrollTop();

    if(parentScrollTop == 0){
      wrapper.removeClass('mid-position').removeClass('end-position');
    }
    else if(parentScrollHeight <= parentHeight + parentScrollTop){
      wrapper.removeClass('mid-position').addClass('end-position');
    }
    else{
      wrapper.addClass('mid-position').removeClass('end-position');
    }
  }
    
  $(document).on('click', '.scroll-arrow',function(){
    var arrow = $(this);
      var wrapper = arrow.parents('.vert-scroll-holder');
        var parent = wrapper.find('.scroll-area');
        var parentHeight = parent.outerHeight();
        var parentScrollHeight = parent[0].scrollHeight;
        var parentScrollTop = Math.round(parent.scrollTop());

    console.log('parentHeight: ', parentHeight, '; parentScrollHeight: ', parentScrollHeight, '; parentScrollTop: ', parentScrollTop);

    if(arrow.hasClass('scroll-up')){
        parent.animate({scrollTop: parentScrollTop - parentHeight}, 200);
    }
    else{
        parent.animate({scrollTop: parentScrollTop + parentHeight}, 200);
    }

    adjustArrows(wrapper);
  })

  $('.scroll-area').on('scroll', function(){
    var wrapper = $(this).parents('.vert-scroll-holder');
        var parent = wrapper.find('.scroll-area');
        var parentHeight = parent.outerHeight();
        var parentScrollHeight = parent[0].scrollHeight;
        var parentScrollTop = Math.round(parent.scrollTop());

    console.log('parentHeight: ', parentHeight, '; parentScrollHeight: ', parentScrollHeight, '; parentScrollTop: ', parentScrollTop);

    adjustArrows(wrapper);
  })
}

function addPlayingClass(){
   $('.ImageHero__NativeVideoHolder').addClass('playing');
}

$(document).on('click', 'a', function(e){
  if($(this).attr('href').indexOf('#') == 0 && $(this).attr('href').length > 1 && $($(this).attr('href')).length > 0){
      if($(e.target).is('.instafeed-overlay') || location.hash.includes("-insta-feed")){ 
      return;
    }
    e.preventDefault();

  
      var scrolltop = $($(this).attr('href')).offset().top - $('#shopify-section-header').outerHeight() -20;
    
    
    $('html, body').animate({ scrollTop: scrolltop}, 300);
    return false;
  }
})

$(document).ready(function(){
if($('.vert-scroll-holder').length > 0){

  vertScrollFunctions();
}


  $('.ProductForm [type="radio"]').change(function(){
    updateAvailableOptions($('.ProductForm'), $(this));
  })
    updateAvailableOptions($('.ProductForm'), '');
  // if($('[data-section-type="collection"]').length > 0){
  //   collectionFunctions();
  // }

  if($('[data-section-type="faq"]').length > 0){
    faqFunctions();
  }
  
  $('[data-section-type="announcement-bar"]').each(function(){

    
  var index = 1;
    var parent = $(this);
    var time = parent.data('time');
    setInterval(function(){
    
      index++;
      
      if(parent.find('.AnnouncementBar__Content[data-index="'+index+'"]').length == 0){
        
        index = 1;
      }
      
        parent.find('.AnnouncementBar__Content:not([data-index="'+index+'"])').slideUp(300);
        parent.find('.AnnouncementBar__Content[data-index="'+index+'"]').slideDown(300);
      
    }, time)
    
  })

  $('.ProductForm--dynamic').submit(function(e){

    e.preventDefault();
    quickAddToCart($(this));
    
  })

  $('.Blog__TagList').change(function(){

    window.location.href = $(this).val();
  })
  
  if($(window).width() < 876){
      $('[data-section-type="native-background-video"]').click(function(){

        console.log('video should play now');
        $(this).find('video')[0].play();

        $(this).find('.ImageHero__NativeVideoHolder').addClass('playing');
      })

    $('[data-section-type="native-background-video"] video').on("play", function (e) {
$(this).parents('[data-section-type="native-background-video"]').find('.ImageHero__NativeVideoHolder').addClass('playing');
});
  }
  
  $(document).on('click', '.quick-add-holder', function(e){
    e.preventDefault();
  });

  $('.ProductItem select').change(function(){
    var parent = $(this).parents('.ProductItem');
    var productData = JSON.parse(parent.find('[data-product-json]').html());
  var newVariantId;
    productVariants = productData.product.variants;
  // console.log('productVariants: ', productVariants);

    for(var i = 0; i < productVariants.length; i++){
      
      var optionValue1  = null;
      var optionValue2  = null;
      var optionValue3  = null;
      if(parent.find('[data-option-index="1"]').length > 0){
        optionValue1 = parent.find('[data-option-index="1"]').val();
      }
      if(parent.find('[data-option-index="2"]').length > 0){
        optionValue2 = parent.find('[data-option-index="2"]').val();
      }
      if(parent.find('[data-option-index="3"]').length > 0){
        optionValue3 = parent.find('[data-option-index="3"]').val();
      }

      if(optionValue1 == productVariants[i].option1 && optionValue2 == productVariants[i].option2 && optionValue3 == productVariants[i].option3){

        newVariantId = productVariants[i].id;
        parent.find('[name="id"]').val(productVariants[i].id);

        console.log(parent.find('[name="id"]').val());
      }
    }

    var selectedOption = parent.find('[name="id"] option[value="'+newVariantId+'"]');
  if(selectedOption.length == 0){
      console.log('option not foound: [name="id"] option[value="'+newVariantId+'"]');
      parent.find('.ProductForm__AddToCart').addClass('disabled').attr('disabled', true);
      }
      else if(selectedOption.prop('disabled') == true){
      console.log('option is disabled: [name="id"] option[value="'+newVariantId+'"]');
      parent.find('.ProductForm__AddToCart').addClass('disabled').attr('disabled', true);
    }
    else{
      console.log('option available: [name="id"] option[value="'+newVariantId+'"]');
      parent.find('.ProductForm__AddToCart').removeClass('disabled').removeAttr('disabled');
    }
  })

  $('.ProductItem .ProductForm__AddToCart').click(function(){
    var parent = $(this).parents('.ProductItem');

    quickAddToCart(parent.find('form'));
  })

  $('.read-more').each(function(){
    
    var parent = $(this).parents('.collectionDescription');

    parent.find('.descContent').css('max-height', 'calc(' + parent.find('.descContent').css('line-height') + ' * 1 - 4px)');
    console.log('parentLineHeight * 2', parent.find('.descContent').css('line-height'));
  })
    
  $('.read-more').click(function(){

    var parent = $(this).parents('.collectionDescription');

    parent.find('.descContent').css('max-height', parent.find('.descContent')[0].scrollHeight);

    $(this).slideUp(300);
  })
})
